import { AbstractControl } from "@angular/forms";

export class PasswordValidator {

    static matchPassword(control: AbstractControl): { [key: string]: boolean } | null {
        let password = control.get('password').value;
        let confirmPassword = control.get('confirmPassword').value;
        if (password != confirmPassword) {
            control.get('confirmPassword').setErrors({ missMatch: true });
        } else {
            return null;
        }
    }

}