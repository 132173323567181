import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Password } from './settings.model';

const BACKEND_URL_USERS = environment.apiBaseUrl + "/users";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, private router: Router) { }

  // Update user
  updateUser(userData: any) {
    return this.http.put<{ message: string }>(BACKEND_URL_USERS + '/' + userData.id, userData);
  }

  // Get username validated
  validateUsername(usernameData: any) {
    return this.http.post<{ message: string, exists: boolean }>(BACKEND_URL_USERS + '/username', usernameData);
  }

  // Update user password
  updateUserPassword(passwordData: Password) {
    return this.http.put<{ message: string }>(BACKEND_URL_USERS + '/change-password/' + passwordData.id, passwordData);
  }

  // Update notifies to user
  updateNotifyToUser(notifyId: string, userId: string) {
    const notifyFor = {
      notifyId: notifyId,
      userId: userId
    };
    return this.http.put<{ message: string }>(BACKEND_URL_USERS + '/' + userId + '/notify/add', notifyFor);
  }

  // Remove notifies from user
  removeNotifyFromUser(notifyId: string, userId: string) {
    const notifyFor = {
      notifyId: notifyId,
      userId: userId
    };
    return this.http.put<{ message: string }>(BACKEND_URL_USERS + '/' + userId + '/notify/remove', notifyFor);
  }
}
